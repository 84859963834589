<template>
  <router-link
    :to="{ name: 'barbers' }"
    class="block mb-3 text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900">
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="mb-5 card" v-show="!isEditDetail">
    <div class="flex justify-content-between align-items-center">
      <h1 class="tw-text-xl tw-font-bold tw-mb-8 mb-0">Detail Karyawan</h1>
      <Button
        icon="pi pi-pencil"
        label="Edit"
        class="p-button p-button-warning py-2 tw-h-full"
        @click="isEditDetail = true"
      />
    </div>
    <hr class="mt-2 mb-4" />
    <div class="grid mb-5">
      <div class="col-12 md:col-8">
        <div
          class="grid mb-2"
          v-for="column in columns"
          :key="`_${column.field}`"
        >
          <div class="col-5 md:col-4 opacity-60">
            {{ column.header }}
          </div>
          <div class="col-7 md:col-8 tw-font-medium">
            <div v-if="!column?.process">
              {{ data[column.field] }}
            </div>
            <div v-else>
              {{ column.process(data[column.field]) }}
            </div>
          </div>
        </div>
        <BarberInfos
          :salon-name="data?.salon_name"
          :salon-id="data?.salon_id"
          :barber-id="route.params?.id"
        />
      </div>
    </div>
  </div>

  <div class="card" v-show="isEditDetail">
    <div class="flex justify-content-between align-items-center tw-mb-10">
      <h1 class="tw-text-xl tw-font-bold tw-mb-8 mb-0">Edit Pegawai</h1>
      <Button
        icon="pi pi-times"
        label="Batal"
        class="p-button p-button-danger py-2 tw-h-full"
        @click="isEditDetail = false"
      />
    </div>
    <BarberForm @submit="updateBarber" :initial-data="data" />
  </div>

  <BarberServices
    :salon-name="data?.salon_name"
    :salon-id="data?.salon_id"
    :barber-id="route.params?.id"
    @getBarberServices="getBarberServices"
  />

  <BarberAvailabilities
    :salon-name="data?.salon_name"
    :salon-id="data?.salon_id"
    :barber-id="route.params?.id"
    :barberServices="barberServices"
  />
</template>

<script setup>
import BarberServices from "./BarberServices";
import BarberAvailabilities from "./BarberAvailabilities";
import { useRoute } from "vue-router";
import { onBeforeMount, ref } from "vue";
import { useBarberForm } from "@/composables/barber";
import BarberInfos from "./BarberInfos";
import BarberForm from "./Form";

const data = ref({});
const barberServices = ref([]);

const route = useRoute();
const { getDetail, update } = useBarberForm();
const isEditDetail = ref(false);
const columns = [
  { field: "salon_name", header: "Nama Salon" },
  { field: "name", header: "Nama Pegawai" },
  { field: "position", header: "Posisi" },
  {
    field: "gender",
    header: "Jenis Kelamin",
    process: (data) => (data === "M" ? "Pria" : "Wanita"),
  },
  { field: "status_name", header: "Status" },
  {
    field: "hide_rating",
    header: "Sembunyikan Rating",
    process: (data) => (data ? "True" : "False"),
  },
];

const updateBarber = async (data) => {
  await update(route.params.id, data);
  isEditDetail.value = false;
};

const getBarberServices = (data) => {
  barberServices.value = data.value
}

onBeforeMount(async () => {
  data.value = await getDetail(route.params.id);
});
</script>

<style scoped></style>